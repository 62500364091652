<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Did you ever wonder what those small bags of beads are that you often see packaged with
        shoes or electronics? These bags contain silica gel which are small solid (despite the name)
        beads that are used to reduce humidity. While silica gel does not typically react with
        anything, it does readily adsorb water vapour to remove it from the surrounding environment.
        This property makes silica gel an ideal desiccant. While you may not know the actual
        thermodynamic values for the adsorption of water by silica gel, you should be able to
        determine the sign of
        <stemble-latex content="$\Delta\text{H,}$" />
        <stemble-latex content="$\Delta\text{S,}$" />
        and
        <stemble-latex content="$\Delta\text{G}$" />
        for this adsorption process.
      </p>

      <p class="mb-1">
        <stemble-latex content="$\text{a) }\Delta\text{H}$" />
      </p>

      <v-radio-group v-model="inputs.enthalpy" class="pl-8 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">
        <stemble-latex content="$\text{b) }\Delta\text{S}$" />
      </p>

      <v-radio-group v-model="inputs.entropy" class="pl-8 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">
        <stemble-latex content="$\text{c) }\Delta\text{G}$" />
      </p>

      <v-radio-group v-model="inputs.freeEnergy" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question289',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        enthalpy: null,
        entropy: null,
        freeEnergy: null,
      },
      options1: [
        {expression: '$\\text{Less than 0}$', value: 'lt0'},
        {expression: '$\\text{Greater than 0}$', value: 'gt0'},
        {
          expression: '$\\text{Impossible to determine based on given information}$',
          value: 'unknown',
        },
      ],
    };
  },
};
</script>
